<template>
  <ion-page>
    <Header title="Pengaturan Akun" />
    <ion-content :fullscreen="true">
      <ion-list lines="none">
        <ion-item button detail @click="$router.push('/profile')">
          <ion-label>Akun</ion-label>
        </ion-item>
        <ion-item button detail @click="$router.push('/change-password')">
          <ion-label>Ubah Password</ion-label>
        </ion-item>
        <ion-item button detail @click="$router.push('/address')">
          <ion-label>Alamat Pengiriman</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonList, IonLabel, IonItem } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonList,
    IonLabel,
    IonItem,
    Header
  }
})
</script>

<style>

</style>
